import { useTheme } from '@/theme';
import { ThemeIds } from '@/theme/themes';
import { useEffect } from 'react';

interface AchievementIconProps {
    icon: string;
    onLoaded?: () => void;
}

export default function AchievementIcon({ icon, onLoaded }: AchievementIconProps) {
    const { themeId } = useTheme();

    let src = '';
    if (achievementIcon[themeId] && achievementIcon[themeId][icon]) {
        src = achievementIcon[themeId][icon];
    } else if (icon) {
        console.error('Showing fallback achievement icon');
        src = '/assets/global/images/achievements/info.webp';
    }

    useEffect(() => {
        if (!onLoaded) return;
        const img = new Image();
        img.src = src;
        if (img.complete) {
            setTimeout(onLoaded, 0);
        } else {
            img.onload = onLoaded;
        }
    }, [icon, onLoaded]);

    if (!src) return <></>;
    return <img src={src} alt=" " />;
}

const achievementIcon = {
    [ThemeIds.Titan]: {
        points: '/assets/themes/titan/images/achievements/points.webp',
        death: '/assets/themes/titan/images/achievements/death.webp',
        default: '/assets/themes/titan/images/achievements/default.webp',
        info: '/assets/themes/titan/images/achievements/info.webp',
        event: '/assets/themes/titan/images/achievements/death.webp',
    },
    [ThemeIds.Phobos]: {
        points: '/assets/themes/phobos/images/achievements/points.webp',
        death: '/assets/themes/phobos/images/achievements/death.webp',
        default: '/assets/themes/phobos/images/achievements/default.webp',
        info: '/assets/themes/phobos/images/achievements/info.webp',
        event: '/assets/themes/phobos/images/achievements/death.webp',
    },
    [ThemeIds.Hyperion]: {
        points: '/assets/themes/hyperion/images/achievements/points.webp',
        death: '/assets/themes/hyperion/images/achievements/death.webp',
        default: '/assets/themes/hyperion/images/achievements/default.webp',
        info: '/assets/themes/hyperion/images/achievements/info.webp',
        event: '/assets/themes/hyperion/images/achievements/event.png',
    },
};
