import useFontLoader from '@/components/Preloader/useFontLoader';
import useThemeLoader from '@/components/Preloader/useThemeLoader';
import { useEffect, useState } from 'react';

interface PreloaderProps {
    children?: React.ReactNode;
}

const startTime = Date.now();

export default function Preloader({ children }: PreloaderProps) {
    const [ready, setReady] = useState(false);
    const fonts = useFontLoader();
    const { ready: themeReady, error } = useThemeLoader();

    useEffect(() => {
        if (fonts.ready && themeReady) {
            const endTime = Date.now();
            const loadTime = endTime - startTime;
            console.log(`Asset load time: ${loadTime / 1000}s`);
            setReady(true);
        }
    }, [fonts.ready, themeReady]);

    // TODO: handle error state

    if (!ready) {
        // Loading screen is inside index.html
        return <></>;
    }
    return <>{children}</>;
}
