import { AuthButton } from '../../components/LoginButton';
import { ButtonLink } from '../../components/ButtonLink';
import { loginAsGuest } from '@/state/features/auth';
import { sendEvent } from '@/utils/analytics';
import { useAppDispatch } from '@/state/store';
import { useNavigateToLogin, useNavigateToSignup } from '@/hooks/auth';

import * as styles from '../../styles.module.scss';

export default function LandingPageButtons() {
    const dispatch = useAppDispatch();
    const navigateToSignup = useNavigateToSignup();
    const navigateToLogin = useNavigateToLogin();
    return (
        <div>
            <p className={styles.smallText} style={{ marginBottom: 20 }}>
                Creating an account allows you to participate in chat and gain access to exclusive content.
            </p>
            <AuthButton
                text="Sign up"
                onClick={() => {
                    sendEvent('landing_page_sign_up_click');
                    navigateToSignup();
                }}
            />
            <AuthButton
                isHighlighted={false}
                text="Continue as Guest"
                onClick={() => {
                    dispatch(loginAsGuest());
                    sendEvent('landing_page_continue_as_guest_click');
                }}
                style={{ marginTop: 16 }}
            />
            <span className={styles.bottomText}>
                Don't have an account?{' '}
                <ButtonLink
                    isHighlighted
                    onClick={() => {
                        sendEvent('landing_page_log_in_click');
                        navigateToLogin();
                    }}
                >
                    Log in
                </ButtonLink>
            </span>
        </div>
    );
}
