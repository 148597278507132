import React, { useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { sendEvent } from '@/utils/analytics';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { setCurrentlyViewingMedia } from '@/state/features/app';
import { LinkButton } from '@/components/Forms/Button';
import IconButton from '@/components/IconButton';

import * as styles from './styles.module.scss';

export default function MediaViewer() {
    const dispatch = useAppDispatch();
    const currentlyViewingMedia = useAppSelector((state) => state.app.currentlyViewingMedia);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (currentlyViewingMedia?.resourceUrl) {
            sendEvent('view_media', currentlyViewingMedia);

            setTimeout(() => {
                closeButtonRef.current?.focus();
            }, 200);
        }
    }, [currentlyViewingMedia?.resourceUrl]);

    if (!currentlyViewingMedia?.resourceUrl || !currentlyViewingMedia?.resourceType) return null;

    const ImageContainer = () => (
        <TransformWrapper
            limitToBounds={true}
            centerOnInit={true}
            doubleClick={{ mode: 'toggle' }}
            centerZoomedOut={true}
            pinch={{ step: 10 }}
            panning={{ velocityDisabled: true }}
        >
            <TransformComponent wrapperClass={styles.transformWrapper} contentClass={styles.transformContent}>
                <img
                    className={styles.imageViewerImage}
                    src={currentlyViewingMedia.resourceUrl}
                    alt={currentlyViewingMedia.description ?? ' '}
                />
            </TransformComponent>
        </TransformWrapper>
    );

    const VideoContainer = () => {
        if (currentlyViewingMedia.resourceUrl.includes('youtube.com/watch')) {
            const videoId = new URL(currentlyViewingMedia.resourceUrl).searchParams.get('v');
            if (videoId) {
                const embedUrl = `https://www.youtube.com/embed/${videoId}`;
                return (
                    <iframe
                        className={styles.video}
                        src={embedUrl}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                );
            }
            console.warn('Invalid YouTube video URL');
        } else {
            console.warn('Unrecognised video type');
        }
        console.warn('Cannot display content');
        return 'Cannot display content';
    };

    const LinkContainer = () => {
        if (
            currentlyViewingMedia.additionalImageUrl &&
            currentlyViewingMedia.resourceUrl &&
            currentlyViewingMedia.description
        ) {
            return (
                <>
                    <img
                        className={styles.externalLinkImage}
                        src={currentlyViewingMedia.additionalImageUrl}
                        alt=" "
                    ></img>
                    <LinkButton
                        className={styles.externalLinkButton}
                        href={currentlyViewingMedia.resourceUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {currentlyViewingMedia.description}
                    </LinkButton>
                </>
            );
        }
        console.warn('Cannot display externalLink');
        return 'Cannot display content';
    };

    const getMedia = () => {
        switch (currentlyViewingMedia.resourceType) {
            case 'image':
                return ImageContainer();
            case 'video':
                return VideoContainer();
            case 'externalLink':
                return LinkContainer();
            default:
                console.warn('Cannot display content, unrecognised type: ' + currentlyViewingMedia.resourceType);
                return 'Cannot display content';
        }
    };

    return (
        <div className={styles.MediaViewer}>
            <div className={styles.overlay}></div>
            <div className={styles.modalContent} aria-modal>
                <IconButton
                    iconType="close"
                    onClick={() => dispatch(setCurrentlyViewingMedia(null))}
                    className={styles.closeButton}
                    buttonStyle="round"
                    ariaLabel="Close Image"
                    buttonRef={closeButtonRef}
                />
                <div className={styles.mediaContainer}>{getMedia()}</div>
            </div>
        </div>
    );
}

/* 
Can be activated using arise events such as:
arise.postMessage('view-media', {resourceUrl: 'http://www.link.com', resourceType: 'externalLink', description: 'Click me for stuff', additionalImageUrl: 'www.image.com/image.png' });
arise.postMessage('view-media', {resourceUrl: 'https://url.com/2KULjU8xlO.webp', resourceType: 'image', description: 'Something'});
arise.postMessage('view-media', {resourceUrl: 'https://www.youtube.com/watch?v=vlxi3CwQd4Q', resourceType: 'video', description: 'Something'});
*/
