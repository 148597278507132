import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as arise from '@/arise/api';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { loadCurrentUserProfile } from '@/state/features/profile';
import { useTheme } from '@/theme';
import { transitionStates } from '@/utils/pageTransition';
import { pathToURL } from '@/utils/urls';
import { useInitialAuthCheck } from '@/hooks/auth';
import Button from '@/components/Forms/Button';
import FactionPicker from '@/components/FactionPicker';
import AuthInput from '@/components/Forms/AuthInput';
import { useAudio } from '@/hooks/audio';
import { sendEvent } from '@/utils/analytics';
import { homePath, isInsidersInstance } from '@/theme/vars';
import AvatarLayeredPicker from '@/components/AvatarLayeredPicker';
import { Background, Character, Faction, FactionSettings } from '@/models/profile';

import * as styles from './styles.module.scss';

export default function EditProfilePage() {
    const [selectedFactionId, setSelectedFactionId] = useState('');
    const [selectedBackgroundId, setSelectedBackgroundId] = useState('');
    const [selectedCharacterId, setSelectedCharacterId] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [pageTransitionState, setPageTransitionState] = useState(transitionStates.preVisible);
    const [factionPickerTransitionState, setFactionPickerTransitionState] = useState(transitionStates.preVisible);
    const [avatarPickerTransitionState, setAvatarPickerTransitionState] = useState(transitionStates.preVisible);
    const [assetsAreLoaded, setAssetsAreLoaded] = useState(false);
    const [isSendingData, setIsSendingData] = useState(false);
    const [isProfileCreationComplete, setIsProfileCreationComplete] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasCatastrophicError, setHasCatastrophicError] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const profile = useAppSelector((state) => state.profile);
    const { user, loggedIn } = useAppSelector((state) => state.auth);
    const allowUserToSetDisplayName = !isInsidersInstance;

    const { audio } = useAudio();

    useInitialAuthCheck();

    useEffect(() => {
        audio.playOnly(['faction-selector-bg', 'drone-bg']);
    }, []);

    const [factions, setFactions] = useState<Faction[]>();
    const [factionSettings, setFactionSettings] = useState<FactionSettings>();
    const [characters, setCharacters] = useState<Character[]>([]);
    const [backgrounds, setBackgrounds] = useState<Background[]>();

    const loadElements = () => {
        const promises: [Promise<Faction[]>, Promise<FactionSettings>, Promise<Background[]>, Promise<Character[]>] = [
            arise.getFactions(),
            arise.getFactionSettings(),
            arise.getBackgrounds(),
            arise.getCharacters(),
        ];

        const assetIsDefault = (asset) => asset.price === 0;

        Promise.all(promises)
            .then(([factionsData, factionSettingsData, backgroundData, characterData]) => {
                const defaultBackgrounds = backgroundData.filter(assetIsDefault);
                const defaultCharacters = characterData.filter(assetIsDefault);
                if (!factionsData || !factionSettingsData) {
                    setHasCatastrophicError(true);
                    console.error('Onboarding - Factions data or factions settings not available');
                }
                if (!defaultBackgrounds?.length || !defaultCharacters?.length) {
                    setHasCatastrophicError(true);
                    console.error('Onboarding - Not enough default backgrounds or characters provided');
                }
                setFactions(factionsData);
                setFactionSettings(factionSettingsData);
                setBackgrounds(defaultBackgrounds);
                setCharacters(defaultCharacters);
                setAssetsAreLoaded(true);
            })
            .catch((error) => {
                setAssetsAreLoaded(true);
                setHasCatastrophicError(true);
                console.error('Onboarding - Error loading onboarding data', error);
            });
    };

    function playRandomDrum() {
        const random = Math.random();
        if (random < 0.5) {
            audio?.play('drum');
        } else {
            audio?.play('drum2');
        }
    }

    function goToFactionPicker() {
        setCurrentStep(1);
        setTimeout(() => {
            setFactionPickerTransitionState(transitionStates.visible);
        }, 10);
    }
    function goToAvatarPicker() {
        setCurrentStep(2);
        setTimeout(() => {
            setAvatarPickerTransitionState(transitionStates.visible);
        }, 10);
    }

    function onContinue() {
        audio?.play('button-click');
        audio?.play('gust-open');
        setErrorMessage('');
        setFactionPickerTransitionState(transitionStates.postVisible);
        setTimeout(goToAvatarPicker, 1200);
    }

    function onSubmit() {
        audio?.play('button-click');
        if (!user || !selectedBackgroundId || !selectedCharacterId || !selectedFactionId) return;
        if (allowUserToSetDisplayName && !displayName) return;
        audio?.play('slam');
        submitData();
    }

    async function submitData() {
        setIsSendingData(true);
        if (profile.currentUserProfile) {
            sendEvent('profile_update', { faction: selectedFactionId });
            try {
                const body = {
                    factionID: selectedFactionId,
                    backgroundID: selectedBackgroundId,
                    characterID: selectedCharacterId,
                    ...(allowUserToSetDisplayName && { displayName: displayName }),
                };
                await arise.editProfile(profile.currentUserProfile.id, body);
                navigateToSelector();
            } catch (error) {
                setErrorMessage('Could not update profile');
                setIsSendingData(false);
            }
        } else {
            sendEvent('profile_create', { faction: selectedFactionId });
            try {
                const body = {
                    displayName: allowUserToSetDisplayName ? displayName : user.username,
                    factionID: selectedFactionId,
                    backgroundID: selectedBackgroundId,
                    characterID: selectedCharacterId,
                };
                await arise.createProfile(body);
                setIsProfileCreationComplete(true);
                dispatch(loadCurrentUserProfile([user.id]));
                // Hook then navigates to selector once new profile loaded
            } catch (error) {
                setErrorMessage('Could not create profile');
                setIsSendingData(false);
            }
        }
    }

    useEffect(() => {
        if (isProfileCreationComplete && profile.currentUserProfile) {
            navigateToSelector();
        }
    }, [isProfileCreationComplete, profile.currentUserProfile]);

    function onHover() {
        audio?.play('hover');
    }

    function navigateToSelector() {
        setAvatarPickerTransitionState(transitionStates.postVisible);
        setPageTransitionState(transitionStates.postVisible);
        setTimeout(() => {
            navigate(homePath);
            audio.play('wipe-dark1');
        }, 1200);
    }

    useEffect(() => {
        loadElements();
    }, []);

    useEffect(() => {
        if (!isLoaded && loggedIn && user && assetsAreLoaded) {
            setIsLoaded(true);
            setTimeout(() => {
                if (factions?.length === 1) {
                    // Only 1 faction to pick from, so skip this step
                    goToAvatarPicker();
                    setSelectedFactionId(factions[0].id);
                } else {
                    goToFactionPicker();
                }
                setPageTransitionState(transitionStates.visible);
            }, 100);
        }
    }, [loggedIn, user, profile, assetsAreLoaded]);

    useEffect(() => {
        if (user && !profile.currentUserProfileCheckInProgress) dispatch(loadCurrentUserProfile([user.id]));
    }, [user]);

    useEffect(() => {
        if (profile.currentUserProfile) {
            setSelectedFactionId(profile.currentUserProfile.factionID);
            setDisplayName(profile.currentUserProfile.displayName);
        }
    }, [profile.currentUserProfile]);

    // TODO: loading
    if (!isLoaded) return <div></div>;

    function renderPicker() {
        switch (currentStep) {
            case 1:
                return (
                    <FactionPicker
                        factions={factions}
                        factionSubtitle={factionSettings.factionCardSubtitle}
                        backgroundImagePath={pathToURL(factionSettings.factionCardBackgroundImagePath)}
                        textBackgroundImagePath={pathToURL(factionSettings.factionCardTextBackgroundImagePath)}
                        highlightImagePath={pathToURL(factionSettings.factionCardHighlightImagePath)}
                        selectedFactionId={selectedFactionId}
                        onFactionSelected={(factionId) => {
                            playRandomDrum();
                            setSelectedFactionId(factionId);
                            setErrorMessage('');
                        }}
                        onFactionMouseEnter={onHover}
                        transitionState={factionPickerTransitionState}
                    />
                );
            case 2:
                return (
                    <>
                        {allowUserToSetDisplayName && (
                            <AuthInput
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                placeholder="Display Name"
                                type="text"
                                id="displayName"
                                name="Display Name"
                                style={{ width: 350 }}
                            />
                        )}
                        <div>
                            <AvatarLayeredPicker
                                characters={characters}
                                backgrounds={backgrounds}
                                selectedCharacterId={selectedCharacterId}
                                selectedBackgroundId={selectedBackgroundId}
                                onBackgroundSelected={(id) => {
                                    playRandomDrum();
                                    setSelectedBackgroundId(id);
                                    setErrorMessage('');
                                }}
                                onCharacterSelected={(id) => {
                                    setSelectedCharacterId(id);
                                }}
                                onAvatarMouseEnter={onHover}
                                transitionState={avatarPickerTransitionState}
                            />
                        </div>
                    </>
                );
            default:
                return <></>;
        }
    }

    function renderButton() {
        switch (currentStep) {
            case 1:
                return (
                    <Button
                        className={styles.authNextStepButton}
                        onClick={onContinue}
                        onSubmit={onContinue}
                        disabled={!selectedFactionId}
                    >
                        Continue
                    </Button>
                );
            case 2:
                return (
                    <Button
                        className={styles.authNextStepButton}
                        onClick={onSubmit}
                        onSubmit={onSubmit}
                        disabled={
                            !selectedCharacterId ||
                            !selectedBackgroundId ||
                            (allowUserToSetDisplayName && !displayName) ||
                            isSendingData
                        }
                    >
                        Continue
                    </Button>
                );
            default:
                return <></>;
        }
    }
    const step2Title = allowUserToSetDisplayName ? 'Choose your avatar and display name' : 'Choose your avatar';

    return (
        <div
            className={classNames(styles.editProfilePage, styles['step' + currentStep], styles[pageTransitionState], {
                [styles.loaded]: isLoaded,
            })}
            style={{
                backgroundImage: `url(${theme.customJSON.editProfileBackgroundImage})`,
            }}
        >
            {hasCatastrophicError && (
                <div className={styles.catastrophicError}>
                    <h2>
                        Something has gone wrong. <br />
                        Please try again later.
                    </h2>
                </div>
            )}
            {!hasCatastrophicError && (
                <div className={styles.editProfilePage__pickerContainer}>
                    <h2 className={styles.editProfilePage__pickerContainerTitle}>
                        {currentStep === 1 ? factionSettings.factionPickerTitle : step2Title}
                    </h2>
                    {renderPicker()}
                    <div className={styles.editProfilePage__bottom}>
                        {renderButton()}
                        <p className={styles.editProfilePage__errorMessage}>{errorMessage}</p>
                    </div>
                </div>
            )}
        </div>
    );
}
