import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useTheme } from '@/theme';
import { homePath } from '@/theme/vars';
import { useAppSelector } from '@/state/store';
import LandingPageButtons from '@/components/AriseAuth/pages/LandingPageButtons';

import * as styles from './styles.module.scss';

export default function LandingPage() {
    const loggedIn = useAppSelector((state) => state.auth.loggedIn);
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn) {
            navigate(homePath);
        }
    }, [loggedIn]);

    const data = theme.customJSON?.login;
    return (
        <div className={styles.LandingPage} style={{ backgroundImage: `url("${data?.backgroundImageUrl}")` }}>
            <div className={styles.container}>
                {data?.logoImageUrl && <img className={styles.logo} src={data?.logoImageUrl} alt="Logo" />}
                <div className={styles.content}>
                    <div className={styles.landingText}>
                        {data?.title && <h2>{data?.title}</h2>}
                        {data?.subtitle && <p>{data?.subtitle}</p>}
                    </div>
                    <div
                        className={classNames(styles.formContainer, {
                            [styles.dividerLine]: data?.title || data?.subtitle,
                        })}
                    >
                        <LandingPageButtons />
                    </div>
                </div>
            </div>
        </div>
    );
}
