import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { setProfileAsset, setProfileTitle } from '@/utils/profile';
import { sendEvent } from '@/utils/analytics';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { queueAchievement, setCurrentlyViewingPrize } from '@/state/features/app';
import { pathToURL } from '@/utils/urls';
import Button, { LinkButton } from '@/components/Forms/Button';
import Modal from '@/components/Modal';

import * as styles from './styles.module.scss';

interface PostPurchaseScreenProps {
    classname?: string;
}

const INITIAL_COPY_BUTTON_TEXT = 'Copy code to clipboard';
const INITIAL_PROFILE_ASSET_BUTTON_TEXT = 'Apply to profile';

export const RedeemPrizeModal = ({ classname }: PostPurchaseScreenProps) => {
    const dispatch = useAppDispatch();
    const prize = useAppSelector((state) => state.app.currentlyViewingPrize);
    const [isVisible, setIsVisible] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [copyButtonText, setCopyButtonText] = useState(INITIAL_COPY_BUTTON_TEXT);
    const [applyProfileAssetButtonText, setApplyProfileAssetButtonText] = useState(INITIAL_PROFILE_ASSET_BUTTON_TEXT);

    useEffect(() => {
        if (prize?.id) {
            // Reset state on open
            setIsCopied(false);
            setCopyButtonText(INITIAL_COPY_BUTTON_TEXT);
            setApplyProfileAssetButtonText(INITIAL_PROFILE_ASSET_BUTTON_TEXT);
            setIsVisible(true);
            sendEvent('view_prize', { prizeID: prize?.id });

            if (prize.type === 'profile_title' && prize.profileTitle?.id && prize.profileTitle?.title) {
                dispatch(
                    queueAchievement({
                        actionPrefix: 'You earned the title',
                        action: prize.profileTitle.title,
                        type: 'success',
                        icon: 'default',
                    }),
                );
            }
        }
    }, [prize?.id]);

    const closeModal = () => {
        setIsVisible(false);
        // To allow for close animation
        setTimeout(() => {
            dispatch(setCurrentlyViewingPrize(null));
        }, 300);
    };

    const copyCodeToClipboard = () => {
        if (prize.type !== 'external_voucher') return;
        if (!prize.voucherCode?.code) {
            console.error('Could not copy voucher code, not found');
            setCopyButtonText('Failed to copy');
            return;
        }
        try {
            navigator.clipboard.writeText(prize.voucherCode.code).then(() => {
                setCopyButtonText('Copied!');
                setIsCopied(true);
                sendEvent('prize_modal_copy_code', { prizeID: prize?.id });
            });
        } catch (error) {
            setCopyButtonText('Failed to copy');
            console.error('Failed to copy:', error);
        }
    };

    const getCtaButtons = () => {
        switch (prize.type) {
            case 'external_voucher':
                return (
                    <>
                        <Button
                            className={classNames(styles.ctaButton, styles.firstButton, {
                                [styles.lowlight]: isCopied,
                                [styles.highlight]: !isCopied,
                            })}
                            onClick={() => {
                                copyCodeToClipboard();
                            }}
                        >
                            {copyButtonText}
                        </Button>
                        <LinkButton
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classNames(styles.ctaButton, {
                                [styles.lowlight]: !isCopied,
                                [styles.highlight]: isCopied,
                            })}
                            onClick={() => sendEvent('prize_modal_visit_store', { prizeID: prize?.id })}
                            href={prize.externalUrl}
                        >
                            {prize.ctaText}
                        </LinkButton>
                    </>
                );
            case 'profile_asset':
                return (
                    <>
                        <Button
                            className={classNames(styles.highlight, styles.ctaButton)}
                            onClick={() => {
                                setApplyProfileAssetButtonText('Applying...');
                                setProfileAsset(prize.profileAsset?.type, prize.profileAsset?.id).then(
                                    (wasSuccessful) => {
                                        if (wasSuccessful) {
                                            setApplyProfileAssetButtonText('Applied!');
                                            sendEvent('prize_modal_apply_asset', { prizeID: prize?.id });
                                        } else {
                                            setApplyProfileAssetButtonText('Failed to apply');
                                        }
                                    },
                                );
                            }}
                        >
                            {applyProfileAssetButtonText}
                        </Button>
                    </>
                );
            case 'profile_title':
                return (
                    <>
                        <Button
                            className={classNames(styles.highlight, styles.ctaButton)}
                            onClick={() => {
                                setApplyProfileAssetButtonText('Applying...');
                                setProfileTitle(prize.profileTitle?.id).then((wasSuccessful) => {
                                    if (wasSuccessful) {
                                        setApplyProfileAssetButtonText('Applied!');
                                        sendEvent('prize_modal_apply_title', { prizeID: prize?.id });
                                    } else {
                                        setApplyProfileAssetButtonText('Failed to apply');
                                    }
                                });
                            }}
                        >
                            {applyProfileAssetButtonText}
                        </Button>
                    </>
                );

            default:
                console.error('Could not render prize modal buttons for type ', prize);
                return '';
        }
    };

    return (
        <div className={classNames(styles.RedeemPrizeModal, classname)}>
            <Modal
                isVisible={isVisible}
                onClose={closeModal}
                canScroll
                zIndex={1000}
                leaveSpaceAboveAndBelow={false}
                shouldCloseOnExternalClick
                modalClassname={styles.modal}
                contentClassname={styles.contentContainer}
            >
                {prize && (
                    <>
                        <h2>Your Prize!</h2>
                        <h3>{prize.subtitle}</h3>
                        <div className={styles.prizeContainer}>
                            <img
                                className={styles.prizeImage}
                                src={pathToURL(prize.secondaryImagePath)}
                                alt=" "
                                title={prize.description}
                            ></img>
                            {prize.type === 'external_voucher' && (
                                <div className={styles.codeContainer}>
                                    <span className={styles.codeIntro}>Code:</span>
                                    <input
                                        className={styles.code}
                                        type="text"
                                        value={prize.voucherCode?.code}
                                        readOnly
                                        onClick={copyCodeToClipboard}
                                    />
                                </div>
                            )}
                        </div>
                        <p>{prize.redemptionInstructions}</p>
                        {getCtaButtons()}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.readMoreLink}
                            href={prize.readMoreUrl}
                        >
                            Read more
                        </a>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default RedeemPrizeModal;

/* 
Examples:

arise.postMessage('view-prize', {
    id: '7cf5ab80-d2ca-4c33-9dbc-6648abfcd242',
    createdAt: '2025-01-16T09:35:00Z',
    universeId: '81bd63e9-5380-4f8a-9d6a-5a3c2bd6ef96',
    subtitle: 'Profile Asset',
    ctaText: 'Apply to profile',
    readMoreUrl: 'https://website.com/prizes',
    description: 'Cool background',
    mainImagePath: 'c44b29d8-a41f-4fc9-9e6b-ade2e117c92a/reveal-content/uxJ7vbkmpc.webp',
    secondaryImagePath: '/images/prizes/secondary-image.png',
    redemptionInstructions: 'To redeem, simply head to your profile and apply the changes.',
    sfxUrl: 'https://example.com/audio/sfx.mp3',
    externalUrl: 'https://website.com/prizes',
    type: 'profile_asset',
    profileAsset: {
        id: '89241bc0-82f1-4d65-a1b3-59ca8842cb5d',
        type: 'background',
    }
});

arise.postMessage('view-prize', {
    id: '7cf5ab80-d2ca-4c33-9dbc-6648abfcd242',
    createdAt: '2025-01-16T09:35:00Z',
    universeId: '81bd63e9-5380-4f8a-9d6a-5a3c2bd6ef96',
    subtitle: 'Physical prize',
    ctaText: 'Visit store',
    readMoreUrl: 'https://website.com/prizes',
    description: '10% off anything in store',
    mainImagePath: 'c44b29d8-a41f-4fc9-9e6b-ade2e117c92a/reveal-content/uxJ7vbkmpc.webp',
    secondaryImagePath: '/images/prizes/secondary-image.png',
    redemptionInstructions: 'Please visit website store to redeem',
    sfxUrl: 'https://example.com/audio/sfx.mp3',
    externalUrl: 'https://website.com/prizes',
    type: 'external_voucher',
    voucherCode: {
        prizeId: '7cf5ab80-d2ca-4c33-9dbc-6648abfcd242',
        code: '1234567890',
        createdAt: '2025-01-16T09:35:00Z',
    },
});

...
type: 'profile_title',
profileTitle: {
    id: 'Hustler',
    title: 'Hustler',
    rarity: 'Rare'
}
...
*/
