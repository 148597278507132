// Temporary place to put themes.
// In the future these should be loaded from a database.
// TODO - Remove things from here which are now delivered via CMS

import { Theme } from '@/models/instance';

export enum ThemeIds {
    default = 'default',
    Titan = 'titan',
    Phobos = 'phobos',
    Hyperion = 'hyperion',
}

export const defaultTheme: Theme = {
    themeId: ThemeIds.default,
    title: '',
    loaded: false,
    assetBasePath: '/assets',
    fonts: {
        primary: 'Arial',
        secondary: 'Times New Roman',
        tertiary: 'Courier New',
        quaternary: 'Courier New',
    },
    files: {
        logo: '',
        splash_bg: '',
        inventory_backdrop: '',
        instance_mark: '',
    },
    tokens: {
        AriseTheme1RarityRarityCommonLight: '#7f8693ff',
        AriseTheme1RarityRarityCommonMid: '#292f3aff',
        AriseTheme1RarityRarityCommonDark: '#1c2129ff',
        AriseTheme1RarityRarityUncommonLight: '#8fff97ff',
        AriseTheme1RarityRarityUncommonMid: '#265d3fff',
        AriseTheme1RarityRarityUncommonDark: '#12251bff',
        AriseTheme1RarityRarityRareLight: '#a7f0feff',
        AriseTheme1RarityRarityRareMid: '#19646fff',
        AriseTheme1RarityRarityRareDark: '#0e2f34ff',
        AriseTheme1RarityRarityEpicLight: '#e569fbff',
        AriseTheme1RarityRarityEpicMid: '#582f59ff',
        AriseTheme1RarityRarityEpicDark: '#3a1f3bff',
        AriseTheme1RarityRarityLegendaryLight: '#fd8917ff',
        AriseTheme1RarityRarityLegendaryMid: '#803e18ff',
        AriseTheme1RarityRarityLegendaryDark: '#40210fff',
        AriseTheme1StateStateErrorLight: '#fc3232ff',
        AriseTheme1StateStateErrorMid: '#7f0b10ff',
        AriseTheme1StateStateErrorDark: '#200506ff',
        AriseTheme1StateStateSuccessLight: '#8fff97ff',
        AriseTheme1StateStateSuccessMid: '#265d3fff',
        AriseTheme1StateStateSuccessDark: '#12251bff',
        AriseTheme1StateStateDefaultLight: '#7f8693ff',
        AriseTheme1StateStateDefaultMid: '#292f3aff',
        AriseTheme1StateStateDefaultDark: '#1c2129ff',
        AccentTheme1InterfaceMonoInterfaceMonoWhite: '#ffffffff',
        AccentTheme1InterfaceMonoInterfaceMonoActive: '#d7d7d7ff',
        AccentTheme1InterfaceMonoInterfaceMonoForeground: '#a5a5a5ff',
        AccentTheme1InterfaceMonoInterfaceMonoMidground: '#2c2c2cff',
        AccentTheme1InterfaceMonoInterfaceMonoBackground: '#171717ff',
        AccentTheme1InterfaceMonoInterfaceMonoBlack: '#000000ff',
        AccentTheme1InterfaceBrandInterfaceActive: '#a8b2bcff',
        AccentTheme1InterfaceBrandInterfaceForeground: '#626b79ff',
        AccentTheme1InterfaceBrandInterfaceMidground: '#2a2e36ff',
        AccentTheme1InterfaceBrandInterfaceBackground: '#15191fff',
        AccentTheme1BorderBorderDefault: '#d49d76ff',
        AccentTheme1BorderBorderHighlight: '#eec8aeff',
        AccentTheme1BorderBorderInactive: '#5f402aff',
        AccentTheme1BorderBorderActive: '#f3dfd0ff',
        AccentTheme1BrandPaletteBrandAccent1: '#f4b300ff',
        AccentTheme1BrandPaletteBrandAccent2: '#ca8e54ff',
        AccentTheme1BrandPaletteBrandContrast1: '#93b6c6ff',
        AccentTheme1BrandPaletteBrandContrast2: '#57b4ddff',
        AccentTheme1BrandPaletteBrandScale1: '#d4a98aff',
        AccentTheme1BrandPaletteBrandScale2: '#a77e61ff',
        AccentTheme1BrandPaletteBrandScale3: '#3f3128ff',
        AccentTheme1BrandPaletteBrandColour: '#ca8e54ff',
        AccentTheme1BrandPaletteBrandColourContrast: '#ffffffff',
        AccentTheme1ButtonButtonDefault: '#d49d76ff',
        AccentTheme1ButtonButtonHighlight: '#e2aa83ff',
        AccentTheme1ButtonButtonActive: '#f1c2a2ff',
        AccentTheme1ButtonButtonInactive: '#594130ff',
        AccentTheme1ButtonButtonSecondaryDefault: '#8e9db3ff',
        AccentTheme1ButtonButtonSecondaryHighlight: '#a3b2c9ff',
        AccentTheme1ButtonButtonSecondaryActive: '#b3cbd9ff',
        AccentTheme1ButtonButtonSecondaryInactive: '#333b47ff',
        AccentTheme1TextTextDefault: '#ffffffff',
        AccentTheme1TextTextInactive: '#222222ff',
        AccentTheme1TextTextBrand2: '#ca8e54ff',
        AccentTheme1TextTextBrand1: '#d49d76ff',
        AccentTheme1TextTextContrastDefault: '#151515ff',
        AccentTheme1TextTextLink: '#a5a5a5ff', // Added manually for now
        // LEGACY
        LEGACYAccentTheme1ButtonButtonSecondary: '#3a3a3aff',
        LEGACYAccentTheme1TextTextActive: '#ffffffff',
        LEGACYAccentTheme1TextTextSecondary: '#9d9d9dff',
        LEGACYAccentTheme1InterfaceInterfaceActive: '#9d9d9dff',
        LEGACYAccentTheme1InterfaceInterfaceForeground: '#2a2a2aff',
        LEGACYAccentTheme1InterfaceInterfaceMidground: '#141414ff',
        LEGACYAccentTheme1InterfaceInterfaceBackground: '#0f0f0fff',
        LEGACYAccentTheme1InterfaceInterfaceWhite: '#ffffffff',
        LEGACYAccentTheme1InterfaceInterfaceGrey: '#828282ff',
        LEGACYAccentTheme1InterfaceInterfaceBlack: '#0b0b0bff',
        LEGACYAccentTheme1BrandColour: '#8c8c8cff',
        LEGACYAccentTheme1BrandColourContrast: '#0b0b0bff',
    },
    customJSON: {},
};

export const TitanTheme: Theme = {
    ...defaultTheme,
    themeId: ThemeIds.Titan,
    fonts: {
        primary: 'CheapSignage',
        secondary: 'Alexandria',
        tertiary: 'Inter',
        quaternary: 'Oswald',
    },
    tokens: {
        ...defaultTheme.tokens,
        AccentTheme1InterfaceMonoInterfaceMonoWhite: '#ffffffff',
        AccentTheme1InterfaceMonoInterfaceMonoActive: '#d7d7d7ff',
        AccentTheme1InterfaceMonoInterfaceMonoForeground: '#a5a5a5ff',
        AccentTheme1InterfaceMonoInterfaceMonoMidground: '#2c2c2cff',
        AccentTheme1InterfaceMonoInterfaceMonoBackground: '#1b1b1bff',
        AccentTheme1InterfaceMonoInterfaceMonoBlack: '#000000ff',
        AccentTheme1InterfaceBrandInterfaceActive: '#BCA8A8',
        AccentTheme1InterfaceBrandInterfaceForeground: '#796262',
        AccentTheme1InterfaceBrandInterfaceMidground: '#2A2E36',
        AccentTheme1InterfaceBrandInterfaceBackground: '#0F0F0F',
        AccentTheme1BorderBorderDefault: '#8e1f22ff',
        AccentTheme1BorderBorderHighlight: '#f02d32ff',
        AccentTheme1BorderBorderInactive: '#690508ff',
        AccentTheme1BorderBorderActive: '#fb3b40ff',
        AccentTheme1BrandPaletteBrandAccent1: '#d42126ff',
        AccentTheme1BrandPaletteBrandAccent2: '#d42126ff', // Changed this manually for event colours
        AccentTheme1BrandPaletteBrandContrast1: '#93b6c6ff',
        AccentTheme1BrandPaletteBrandContrast2: '#57b4ddff',
        AccentTheme1BrandPaletteBrandScale1: '#d4a98aff',
        AccentTheme1BrandPaletteBrandScale2: '#a77e61ff',
        AccentTheme1BrandPaletteBrandScale3: '#3f3128ff',
        AccentTheme1BrandPaletteBrandColour: '#d42126ff',
        AccentTheme1BrandPaletteBrandColourContrast: '#ffffffff',
        AccentTheme1ButtonButtonDefault: '#d42126ff',
        AccentTheme1ButtonButtonHighlight: '#ff604bff',
        AccentTheme1ButtonButtonActive: '#ff2e00ff',
        AccentTheme1ButtonButtonInactive: '#3f1f1fff',
        AccentTheme1ButtonButtonSecondaryDefault: '#a7a7a7ff',
        AccentTheme1ButtonButtonSecondaryHighlight: '#cececeff',
        AccentTheme1ButtonButtonSecondaryActive: '#d8d8d8ff',
        AccentTheme1ButtonButtonSecondaryInactive: '#505050ff',
        AccentTheme1TextTextDefault: '#ffffffff',
        AccentTheme1TextTextInactive: '#505050ff',
        AccentTheme1TextTextBrand2: '#ca8e54ff',
        AccentTheme1TextTextBrand1: '#ff473bff',
        AccentTheme1TextTextContrastDefault: '#151515ff',
        AccentTheme1TextTextLink: '#a5a5a5ff', // Added manually for now
        // LEGACY
        LEGACYAccentTheme1ButtonButtonSecondary: '#dcdcdcff',
        LEGACYAccentTheme1TextTextActive: '#ffffffff',
        LEGACYAccentTheme1TextTextSecondary: '#dcdcdcff',
        LEGACYAccentTheme1InterfaceInterfaceActive: '#ffffffff',
        LEGACYAccentTheme1InterfaceInterfaceForeground: '#3d3d3dff',
        LEGACYAccentTheme1InterfaceInterfaceMidground: '#1e1e1eff',
        LEGACYAccentTheme1InterfaceInterfaceBackground: '#151515ff',
        LEGACYAccentTheme1InterfaceInterfaceWhite: '#ffffffff',
        LEGACYAccentTheme1InterfaceInterfaceGrey: '#bdbdbdff',
        LEGACYAccentTheme1InterfaceInterfaceBlack: '#000000ff',
        LEGACYAccentTheme1BrandColour: '#e81f25ff',
        LEGACYAccentTheme1BrandColourContrast: '#ffffffff',
    },
};

export const PhobosTheme: Theme = {
    ...defaultTheme,
    themeId: ThemeIds.Phobos,
    fonts: {
        primary: 'ChakraPetchTitle',
        secondary: 'ChakraPetch',
        tertiary: 'ChakraPetch',
        quaternary: 'ChakraPetch',
    },
    tokens: {
        ...TitanTheme.tokens,
    },
};

export const HyperionTheme: Theme = {
    ...defaultTheme,
    themeId: ThemeIds.Hyperion,
    fonts: {
        primary: 'ChakraPetchTitle',
        secondary: 'ChakraPetch',
        tertiary: 'ChakraPetch',
        quaternary: 'HomePlanetBB',
    },
    tokens: {
        ...defaultTheme.tokens,
        AccentTheme1InterfaceMonoInterfaceMonoWhite: '#ffffffff',
        AccentTheme1InterfaceMonoInterfaceMonoActive: '#d7d7d7ff',
        AccentTheme1InterfaceMonoInterfaceMonoForeground: '#a5a5a5ff',
        AccentTheme1InterfaceMonoInterfaceMonoMidground: '#2c2c2cff',
        AccentTheme1InterfaceMonoInterfaceMonoBackground: '#1b1b1bff',
        AccentTheme1InterfaceMonoInterfaceMonoBlack: '#000000ff',
        AccentTheme1InterfaceBrandInterfaceActive: '#a8b2bcff',
        AccentTheme1InterfaceBrandInterfaceForeground: '#626b79ff',
        AccentTheme1InterfaceBrandInterfaceMidground: '#2a2e36ff',
        AccentTheme1InterfaceBrandInterfaceBackground: '#15191fff',
        AccentTheme1BorderBorderDefault: '#0ab4f3ff',
        AccentTheme1BorderBorderHighlight: '#2fc6ffff',
        AccentTheme1BorderBorderInactive: '#0d8ebfff',
        AccentTheme1BorderBorderActive: '#42c9fcff',
        AccentTheme1BrandPaletteBrandAccent1: '#00a0dcff',
        AccentTheme1BrandPaletteBrandAccent2: '#fbee23ff',
        AccentTheme1BrandPaletteBrandContrast1: '#ffffffff',
        AccentTheme1BrandPaletteBrandContrast2: '#000000ff',
        AccentTheme1BrandPaletteBrandScale1: '#5bd2ffff',
        AccentTheme1BrandPaletteBrandScale2: '#00a0dcff',
        AccentTheme1BrandPaletteBrandScale3: '#0c6485ff',
        AccentTheme1BrandPaletteBrandColour: '#00a0dcff',
        AccentTheme1BrandPaletteBrandColourContrast: '#ffffffff',
        AccentTheme1ButtonButtonDefault: '#00a0dcff',
        AccentTheme1ButtonButtonHighlight: '#0ab4f3ff',
        AccentTheme1ButtonButtonActive: '#00b9ffff',
        AccentTheme1ButtonButtonInactive: '#003447ff',
        AccentTheme1ButtonButtonSecondaryDefault: '#a7a7a7ff',
        AccentTheme1ButtonButtonSecondaryHighlight: '#cececeff',
        AccentTheme1ButtonButtonSecondaryActive: '#d8d8d8ff',
        AccentTheme1ButtonButtonSecondaryInactive: '#505050ff',
        AccentTheme1TextTextDefault: '#ffffffff',
        AccentTheme1TextTextInactive: '#505050ff',
        AccentTheme1TextTextBrand2: '#ca8e54ff',
        AccentTheme1TextTextBrand1: '#00a0dcff',
        AccentTheme1TextTextContrastDefault: '#151515ff',
        AccentTheme1TextTextLink: '#a5a5a5ff', // Added manually for now
    },
};
