import { useNavigate } from 'react-router-dom';

export default function useHyperionEvents() {
    const navigate = useNavigate();

    function onGoto(data: any) {
        const target = data.detail;

        switch (target) {
            case 'prize_reveal':
                const prizeRevealId = process.env.PUBLIC_HYPERION_PRIZE_REVEAL_LOCATION_ID;
                navigate(`/location/${prizeRevealId}`);
                break;
            default:
                console.log('Unknown goto target:', target);
                break;
        }
    }

    return {
        onGoto,
    };
}
