import { useEffect, useState } from 'react';

import { SUPPORT_EMAIL } from '@/theme/vars';
import { passwordChange } from '@/arise/api';
import { isValidEmail } from '@/utils/validation';
import Input from '@/components/Forms/Input';
import Modal from '@/components/Modal';

import { AuthButton } from '../../components/LoginButton';
import * as styles from '../../styles.module.scss';

export default function PasswordReset() {
    const [errorMessage, setErrorMessage] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const [uiStatus, setUiStatus] = useState<'enterDetails' | 'passwordChanged'>('enterDetails');

    async function onSubmit() {
        if (!isValidEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            return;
        }
        if (password !== passwordConfirmation) {
            setErrorMessage('Passwords do not match');
            return;
        }
        try {
            await passwordChange(email, token, passwordConfirmation);
            setUiStatus('passwordChanged');
        } catch (error) {
            setErrorMessage(`Could not reset password, please contact ${SUPPORT_EMAIL}`);
            console.error('Could not reset password', error);
        }
    }

    useEffect(() => {
        const query = window.location.search;
        const urlToken = new URLSearchParams(query).get('token');
        if (!urlToken) {
            setErrorMessage('No token found');
            window.location.href = '/';
        }
        setToken(urlToken);
    }, []);

    const EnterDetails = (
        <div>
            <h2>Reset your password</h2>
            <p>Please enter your new password</p>
            <br />
            <label htmlFor="arise-password-reset-email">Email</label>
            <Input
                className={styles.input}
                id="arise-password-reset-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                autoComplete="email"
            />
            <label htmlFor="arise-password-reset-password">New Password</label>
            <Input
                className={styles.input}
                id="arise-password-reset-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                autoComplete="new-password"
            />
            <label htmlFor="arise-password-reset-password-confirmation">Confirm Password</label>
            <Input
                className={styles.input}
                id="arise-password-reset-password-confirmation"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                placeholder="Password"
                type="password"
                autoComplete="new-password"
            />
            <AuthButton
                disabled={!(token && email && password && passwordConfirmation)}
                text="Change Password"
                onClick={onSubmit}
            />
            <p className={styles.errorMessage}>{errorMessage}</p>
        </div>
    );

    const PasswordChanged = (
        <div>
            <h2>Password Changed</h2>
            <p>Your password has been changed successfully!</p>
            <br />
            <AuthButton text="Back to Home" onClick={() => (window.location.href = '/')} />
        </div>
    );
    return (
        <Modal
            canScroll
            isVisible
            onClose={() => {
                window.location.href = '/';
            }}
            modalClassname={styles.AriseLoginModal}
            closeButtonClassname={styles.closeButton}
            closeButtonStyle="icon"
            shouldCloseOnExternalClick={false}
            zIndex={1000}
        >
            <img className={styles.logo} alt="Those Beyond Logo" src="/assets/global/those-beyond-icon.svg"></img>
            {uiStatus === 'enterDetails' ? EnterDetails : PasswordChanged}
        </Modal>
    );
}
