import { useEffect, useRef, TransitionEvent, useState } from 'react';
import classNames from 'classnames';

import { getMyPrizes } from '@/arise/api';
import { Prize } from '@/models/misc';
import { sendEvent } from '@/utils/analytics';
import { pathToURL } from '@/utils/urls';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useAudio } from '@/hooks/audio';
import { useTheme } from '@/theme';
import { setCurrentlyViewingPrize, setIsMyPrizesOpen } from '@/state/features/app';
import IconButton from '@/components/IconButton';
import LoadingScreen from '@/components/LoadingScreen';
import ItemContainer from '@/components/ItemContainer';

import * as styles from './styles.module.scss';

export default function MyPrizesDrawer() {
    const dispatch = useAppDispatch();
    const { audio } = useAudio();
    const theme = useTheme();
    const isMyPrizesOpen = useAppSelector((state) => state.app.isMyPrizesOpen);

    const [prizeItems, setPrizeItems] = useState<Prize[]>([]);
    const [prizeItemsAreLoading, setPrizeItemsAreLoading] = useState<boolean>(true);

    const closeButtonRef = useRef<HTMLButtonElement>(null);

    function removeDuplicates(items: Prize[]) {
        const uniquePrizesMap = new Map<string, Prize>();
        items?.forEach((item) => {
            uniquePrizesMap.set(item.id, item);
        });

        // Convert the map back to an array
        const uniquePrizes = Array.from(uniquePrizesMap.values());
        return uniquePrizes;
    }

    const loadPrizes = () => {
        getMyPrizes()
            .then((items) => {
                if (Array.isArray(items)) {
                    setPrizeItems(removeDuplicates(items));
                } else {
                    console.error('Error loading prizes');
                }
                setPrizeItemsAreLoading(false);
            })
            .catch(() => {
                setPrizeItemsAreLoading(false);
                console.error('Error loading prizes');
            });
    };

    useEffect(() => {
        if (isMyPrizesOpen) {
            audio?.play('gust-open');
            // Reload data when opened
            setPrizeItemsAreLoading(true);
            loadPrizes();
            sendEvent('view_my_prizes');
        }
    }, [isMyPrizesOpen]);

    // Move focus to drawer when opened
    function onTransitionEnd(e: TransitionEvent<HTMLDivElement>) {
        if (isMyPrizesOpen && e.target === e.currentTarget) {
            setTimeout(() => {
                closeButtonRef.current?.focus();
            }, 200);
        }
    }

    return (
        <div
            className={classNames(styles.MyPrizesDrawer, {
                [styles.isOpen]: isMyPrizesOpen,
            })}
            inert={!isMyPrizesOpen ? '' : undefined}
            onTransitionEnd={onTransitionEnd}
            style={{ backdropFilter: `blur(10px)` }}
        >
            <div className={styles.scrollContainer}>
                <div className={styles.topBar}>
                    <h2 className={styles.title}>Your Prizes!</h2>
                    <IconButton
                        buttonRef={closeButtonRef}
                        iconType="close"
                        buttonStyle="round"
                        ariaLabel="Close My Prizes"
                        className={styles.closeButton}
                        onClick={() => {
                            audio?.play('gust-close');
                            dispatch(setIsMyPrizesOpen(false));
                        }}
                    />
                </div>
                <div
                    className={classNames(styles.content, {
                        [styles.noBackgroundImage]: !theme.customJSON?.profileBackground,
                    })}
                    style={
                        theme.customJSON?.profileBackground
                            ? {
                                  backgroundImage: `url("${theme.customJSON?.profileBackground}")`,
                                  borderImageSource: `url("${theme.customJSON?.profileBackground}")`,
                              }
                            : {}
                    }
                >
                    {prizeItems.length === 0 && <p className={styles.emptyText}>No prizes earned yet</p>}
                    <ul>
                        {prizeItems.map((item, index) => {
                            return (
                                <li key={`my-prize-${index}`}>
                                    <ItemContainer
                                        title={item.subtitle}
                                        imagePath={pathToURL(item.secondaryImagePath)}
                                        backgroundImagePath={theme.customJSON?.cardBackgroundImageUrl}
                                        textBackgroundImagePath={theme.customJSON?.cardTextBackgroundImageUrl}
                                        imageAlt={item.description}
                                        interactable={true}
                                        onSelected={() => dispatch(setCurrentlyViewingPrize(item))}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                    <LoadingScreen hasBackground={false} show={prizeItemsAreLoading} />
                </div>
            </div>
        </div>
    );
}
