import { Instance, Theme } from '@/models/instance';
import { AriseArea, AriseLocation, AriseLocationBase } from '@/models/location';
import { Achievement, Prize, SceneSelectorSettings, ViewableMedia } from '@/models/misc';
import { HyperionTheme, PhobosTheme, TitanTheme, defaultTheme } from '@/theme/themes';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as arise from '@/arise/api';
import { RootState } from '@/state/store';
import { logout } from '@/state/features/auth';
import { pathToURL } from '@/utils/urls';
import { InventoryItem } from '@/models/Inventory';
import { isMobileWidth } from '@/utils/mobile';
import { isFeedOpen, processLocationData } from '@/utils/location';
import { Profile } from '@/models/profile';
import { LS_HASSEENSHOP_KEY } from '@/globals';
import { AriseAuthScreen } from '@/components/AriseAuth';

const instanceToTheme = {
    titan: TitanTheme,
    phobos: PhobosTheme,
    hyperion: HyperionTheme,
};

interface AppState {
    instance: string;
    instanceLoaded: boolean;
    isInstanceLoading: boolean;
    theme: Theme;
    location?: AriseLocation;
    isLocationLoading: boolean;
    locationLoadErrorId?: string;

    isInventoryOpen: boolean;
    inventoryToggledAt: number;
    isInventoryUsingItem: boolean;
    isUnseenItemInInventory?: boolean;

    isProfileOpen: boolean;
    currentlyViewingProfile?: Profile;

    currentlyViewingMedia?: ViewableMedia;
    currentlyViewingPrize?: Prize;

    isMyPrizesOpen: boolean;
    isShopOpen: boolean;

    isSupplyRunLeaderboardOpen?: boolean;
    authModalScreen?: AriseAuthScreen;

    isSidebarOpen: boolean;
    isSnackbarVisible: boolean;
    arisewareIsSnackbarVisible?: boolean; // What ariseware wants the snackbar visibility to be
    isExitLocationModalOpen: boolean;

    universeId?: string;

    isFetchingAreas: boolean;
    isFetchingRegions: boolean;

    areas?: {
        [key: string]: AriseArea;
    };
    locations?: {
        [key: string]: AriseLocation;
    };
    regions?: {
        [key: string]: AriseLocationBase;
    };
    isFetchingLocations: boolean;

    isSettingsMenuShowing: boolean;
    isSwitchingWorld: boolean;
    hasWorldIntroPlayed: boolean;
    newsModalLastVersionSeen?: number;
    shouldShowGuestSignUpModal?: boolean;
    guestModalCount?: number; // How many times app has requested to show guest modal
    hasSeenShop?: boolean;

    inventory: InventoryItem[];
    isFetchingInventory: boolean;

    failedAt?: number;

    achievementQueue: Achievement[];

    shareModalOpen: boolean;
    shareMessage: string;

    sceneSelectorData?: SceneSelectorSettings;

    isPurchaseInProgress?: boolean;
}

const initialState: AppState = {
    instance: '',
    instanceLoaded: false,
    isInstanceLoading: false,
    theme: instanceToTheme[process.env.PUBLIC_INSTANCE] || defaultTheme,
    isLocationLoading: false,

    isInventoryOpen: false,
    inventoryToggledAt: Date.now(),
    isInventoryUsingItem: false,

    isProfileOpen: false,
    isMyPrizesOpen: false,
    isShopOpen: false,
    isSnackbarVisible: true,
    isExitLocationModalOpen: false,
    isSidebarOpen: false,
    isFetchingAreas: false,
    isFetchingLocations: false,
    isFetchingRegions: false,

    // TODO: don't hardcode this
    universeId: window.localStorage.getItem('ARISE_UNIVERSEID') || process.env.PUBLIC_INSTANCE_ID,
    isSettingsMenuShowing: false,
    isSwitchingWorld: false,
    hasWorldIntroPlayed: false,
    guestModalCount: 0,
    hasSeenShop: !!window.localStorage.getItem(LS_HASSEENSHOP_KEY),

    inventory: [],
    isFetchingInventory: false,

    failedAt: undefined,

    achievementQueue: [],

    shareModalOpen: false,
    shareMessage: '',
};

export const initialAppState = initialState;

export const loadInstance = createAsyncThunk<Instance, string>('app/loadInstance', async (universeId: string) => {
    let theme: Theme = defaultTheme;
    if (instanceToTheme[process.env.PUBLIC_INSTANCE]) {
        theme = instanceToTheme[process.env.PUBLIC_INSTANCE];
    }

    const [res, metadata] = await Promise.all([arise.getMedia(universeId), arise.getUniverseMetadata(universeId)]);

    theme = {
        ...theme,
        loaded: true,
        assetBasePath: '/assets',
        customJSON: res.customJSON,
        files: {
            ...theme.files,
            logo: pathToURL(res.logoPath),
            splash_bg: pathToURL(res.splashBgPath),
            inventory_backdrop: pathToURL(res.inventoryBackdropPath),
            instance_mark: pathToURL(res.instanceMarkPath),
        },
        title: metadata.title,
        metadata: metadata,
    };

    return {
        name: universeId,
        theme,
    };
});

export const loadLocation = createAsyncThunk<AriseLocation, string>(
    'app/loadLocation',
    async (locationId: string, thunkApi) => {
        let { locations } = (thunkApi.getState() as RootState).app;
        if (!locations) {
            const res = await thunkApi.dispatch(loadLocations());
            const loc = (res.payload as AriseLocation[]).find((l) => l.id === locationId);
            if (loc) {
                return loc;
            } else {
                throw new Error(locationId);
            }
        }

        const location = locations[locationId];
        if (location) {
            return location;
        }

        const res = await thunkApi.dispatch(loadLocations());
        const loc = (res.payload as AriseLocation[]).find((l) => l.id === locationId);
        if (loc) {
            return loc;
        }
        throw new Error(locationId);
    },
);

export const loadLocations = createAsyncThunk<AriseLocation[] | undefined, undefined>(
    'app/loadLocations',
    async (_, thunkApi) => {
        const res = await arise.getLocations();
        return res;
    },
);

export const loadAreas = createAsyncThunk<AriseArea[] | undefined, undefined>('app/loadAreas', async (_, thunkApi) => {
    const res = await arise.getAreas();
    return res;
});
export const loadRegions = createAsyncThunk<AriseLocationBase[] | undefined, undefined>(
    'app/loadRegions',
    async (_, thunkApi) => {
        const res = await arise.getRegions();
        return res;
    },
);

export const loadSceneSelectorData = createAsyncThunk<SceneSelectorSettings>(
    'app/loadSceneSelectorData',
    async (_, thunkApi) => {
        const res = await arise.getSceneSelectorSettings();
        return res;
    },
);

export const switchWorld = createAsyncThunk<void, [string, string, any]>(
    'app/switchWorld',
    async ([worldId, themeId, navigate], thunkApi) => {
        thunkApi.dispatch(app.actions.setIsSwitchingWorld(true));
        thunkApi.dispatch(app.actions.setShowingSettingsMenu(false));

        // Waiting 1.5 sec to simulate something
        // TODO
        await new Promise((resolve) => setTimeout(resolve, 1500));

        thunkApi.dispatch(app.actions.clearData());
        thunkApi.dispatch(app.actions.setUniverseId(worldId));
        thunkApi.dispatch(logout());
        thunkApi.dispatch(app.actions.setIsSwitchingWorld(false));

        // TODO: clear all state (locations, areas, etc)
        // thunkApi.dispatch(app.actions.setUniverseId(worldId));
        // navigate('/auth/login');
    },
);

export const loadAllLocationData = createAsyncThunk<void, undefined, { state: RootState }>(
    'app/loadAllLocationData',
    async (_, thunkApi) => {
        const state = thunkApi.getState();
        const uId = state.app.universeId;
        if (!uId) {
            console.error('No universe id set');
            return;
        }

        thunkApi.dispatch(loadAreas());
        thunkApi.dispatch(loadLocations());
        thunkApi.dispatch(loadRegions());
    },
);

export const loadInventory = createAsyncThunk<InventoryItem[], undefined, { state: RootState }>(
    'app/loadInventory',
    async (_, thunkApi) => {
        const res = await arise.getInventory();
        return res;
    },
);

export const failedGame = createAsyncThunk<void, { arisewareProjectID: string }, { state: RootState }>(
    'app/failedGame',
    async ({ arisewareProjectID }, thunkApi) => {
        const { dispatch } = thunkApi;
        const s = thunkApi.getState();

        dispatch(app.actions.setHasFailed(true));
        dispatch(app.actions.setIsSidebarOpen(true));
        dispatch(app.actions.setIsSnackbarVisible(true));
        dispatch(app.actions.setIsInventoryOpen(false));
        dispatch(app.actions.setIsExitLocationModalOpen(false));
        dispatch(app.actions.setArisewareIsSnackbarVisible(null));

        await arise.failedAriseware(arisewareProjectID);
    },
);

export const clearFailed = createAsyncThunk<void, undefined, { state: RootState }>(
    'app/clearFailed',
    async (_, thunkApi) => {
        const { dispatch } = thunkApi;

        dispatch(app.actions.setHasFailed(false));

        if (isMobileWidth()) {
            dispatch(app.actions.setIsSidebarOpen(false));
        }
    },
);

export const submitFeedUpdate = createAsyncThunk<void, string, { state: RootState }>(
    'app/failedGame',
    async (type, thunkApi) => {
        const s = thunkApi.getState();
        if (!s.app?.location?.isFeedOpen) return;
        try {
            await arise.submitFeedUpdate(s.app.location.id, type);
        } catch (error) {
            console.error('Failed to submit feed update', error);
        }
    },
);

export const submitArisewareScore = createAsyncThunk<void, any, { state: RootState }>(
    'app/submitArisewareScore',
    async (data, thunkApi) => {
        try {
            await arise.submitArisewareScore(data);
        } catch (error) {
            console.error('Failed to submit arise score', error);
        }
    },
);

const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleInventory(state) {
            state.isInventoryOpen = !state.isInventoryOpen;
        },
        setIsInventoryOpen(state, action) {
            if (Date.now() - state.inventoryToggledAt > 750) {
                state.inventoryToggledAt = Date.now();
                state.isInventoryOpen = action.payload;
            }
        },
        setIsSnackbarVisible(state, action) {
            state.isSnackbarVisible = action.payload;
        },
        setArisewareIsSnackbarVisible(state, action) {
            state.arisewareIsSnackbarVisible = action.payload;
        },
        setIsSupplyRunLeaderboardOpen(state, action) {
            state.isSupplyRunLeaderboardOpen = action.payload;
        },
        setIsExitLocationModalOpen(state, action) {
            state.isExitLocationModalOpen = action.payload;
        },
        toggleSidebarOpen(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        setIsSidebarOpen(state, action) {
            state.isSidebarOpen = action.payload;
        },
        openProfile(state, action) {
            state.currentlyViewingProfile = action.payload;
            state.isSidebarOpen = true;
            state.isProfileOpen = true;
            state.isShopOpen = false;
            state.isMyPrizesOpen = false;
        },
        setCurrentlyViewingMedia(state, action) {
            state.currentlyViewingMedia = action.payload;
        },
        setCurrentlyViewingPrize(state, action) {
            state.currentlyViewingPrize = action.payload;
        },
        closeProfile(state) {
            state.isProfileOpen = false;
        },
        toggleShopOpen(state) {
            if (!state.isShopOpen) {
                // If shop will become open
                state.isProfileOpen = false;
                state.isSidebarOpen = true;
            }
            state.isShopOpen = !state.isShopOpen;
        },
        setIsShopOpen(state, action) {
            if (action.payload === true) {
                // If shop will become open
                state.isProfileOpen = false;
                state.isSidebarOpen = true;
            }
            state.isShopOpen = action.payload;
        },
        setIsMyPrizesOpen(state, action) {
            if (action.payload === true) {
                state.isProfileOpen = true;
                state.isSidebarOpen = true;
            }
            state.isMyPrizesOpen = action.payload;
        },
        setAuthModalScreen(state, action: PayloadAction<AriseAuthScreen>) {
            state.authModalScreen = action.payload;
        },
        updateCurrentlyViewingProfileIfMatches(state, action) {
            if (state.currentlyViewingProfile?.id === action.payload?.id) {
                state.currentlyViewingProfile = action.payload;
            }
        },
        setUniverseId(state, action) {
            window.localStorage.setItem('ARISE_UNIVERSEID', action.payload);
            arise.setUniverseID(action.payload);
            state.universeId = action.payload;
        },
        toggleShowingSettingsMenu(state) {
            state.isSettingsMenuShowing = !state.isSettingsMenuShowing;
        },
        setShowingSettingsMenu(state, action) {
            state.isSettingsMenuShowing = action.payload;
        },
        setIsSwitchingWorld(state, action) {
            state.isSwitchingWorld = action.payload;
        },
        setHasWorldIntroPlayed(state, action) {
            state.hasWorldIntroPlayed = action.payload;
        },
        setNewsModalLastVersionSeen(state, action) {
            state.newsModalLastVersionSeen = action.payload;
        },
        setShouldShowGuestSignUpModal(state, action) {
            state.shouldShowGuestSignUpModal = action.payload;
            if (action.payload === true) {
                state.guestModalCount = state.guestModalCount + 1;
            }
        },
        setHasSeenShop(state, action) {
            state.hasSeenShop = action.payload;
        },
        clearData(state) {
            state.areas = undefined;
            state.locations = undefined;
            state.regions = undefined;
        },
        updateLocation(state, action) {
            const updatedLocation = processLocationData(action.payload);
            if (state.locations) {
                state.locations[updatedLocation.id] = updatedLocation;
                if (state.location?.id === updatedLocation.id) {
                    state.location = updatedLocation;
                }
            }
        },
        reevaluateIfFeedsAreOpen(state) {
            for (var locationKey in state.locations) {
                if (state.locations.hasOwnProperty(locationKey)) {
                    const location = state.locations[locationKey];
                    location.isFeedOpen = isFeedOpen(location);
                }
            }
            if (state.location) {
                state.location.isFeedOpen = isFeedOpen(state.location);
            }
        },
        setInventory(state, action) {
            state.inventory = action.payload;
        },
        toggleHasFailed(state) {
            // It's oddly difficult to call another reducer from within a reducer,
            // so we duplicate the logic here and in the method below
            if (!state.failedAt) {
                state.isProfileOpen = false;
                state.failedAt = Date.now();
            } else {
                state.failedAt = undefined;
            }
        },
        setHasFailed(state, action) {
            if (action.payload) {
                state.isProfileOpen = false;
                state.failedAt = Date.now();
            } else {
                state.failedAt = undefined;
            }
        },
        resetAppState(state) {
            Object.assign(state, initialState);
        },
        selectInventoryItem(state) {
            state.isInventoryOpen = true;
            state.isInventoryUsingItem = true;
            state.inventoryToggledAt = Date.now();
            state.isSnackbarVisible = false;
        },
        setIsInventoryUsingItem(state, action) {
            state.isInventoryUsingItem = action.payload;
        },
        setIsUnseenItemInInventory(state, action) {
            state.isUnseenItemInInventory = action.payload;
        },
        queueAchievement(state, action) {
            state.achievementQueue.push(action.payload);
        },
        removeFirstAchievementFromQueue(state) {
            state.achievementQueue.shift();
        },
        setStartShare(state, action) {
            state.shareMessage = action.payload;
            state.shareModalOpen = true;
        },
        setShareModalOpen(state, action) {
            state.shareModalOpen = action.payload;
        },
        setIsPurchaseInProgress(state, action) {
            state.isPurchaseInProgress = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadInstance.pending, (state, action) => {
            state.isInstanceLoading = true;
        });

        builder.addCase(loadInstance.fulfilled, (state, action) => {
            state.instance = action.payload.name;
            state.theme = {
                ...action.payload.theme,
                loaded: true,
            };

            state.instanceLoaded = true;
            state.isInstanceLoading = false;
        });

        builder.addCase(loadInstance.rejected, (state, action) => {
            console.error('Failed to load instance', action.error);
            state.isInstanceLoading = false;
        });

        builder.addCase(loadLocation.pending, (state, action) => {
            state.isLocationLoading = true;
            state.locationLoadErrorId = null;
        });

        builder.addCase(loadLocation.fulfilled, (state, action) => {
            state.location = action.payload;
            state.isLocationLoading = false;
            state.locationLoadErrorId = null;
        });

        builder.addCase(loadLocation.rejected, (state, action) => {
            console.error('Failed to load location', action.error);
            state.locationLoadErrorId = action?.error?.message;
            state.isLocationLoading = false;
        });

        builder.addCase(loadAreas.pending, (state, action) => {
            state.isFetchingAreas = true;
        });

        builder.addCase(loadAreas.fulfilled, (state, action) => {
            state.areas = {};
            action.payload?.forEach((area) => {
                state.areas![area.id] = area;
            });
            state.isFetchingAreas = false;
        });

        builder.addCase(loadAreas.rejected, (state, action) => {
            console.error('Failed to load areas', action.error);
            state.isFetchingAreas = false;
        });

        builder.addCase(loadLocations.pending, (state, action) => {
            state.isFetchingLocations = true;
        });

        builder.addCase(loadLocations.fulfilled, (state, action) => {
            state.locations = {};
            action.payload?.forEach((location) => {
                state.locations![location.id] = location;
                if (state.location?.id === location.id) {
                    state.location = location;
                }
            });
            state.isFetchingLocations = false;
        });

        builder.addCase(loadLocations.rejected, (state, action) => {
            console.error('Failed to load locations', action.error);
            state.isFetchingLocations = false;
        });

        builder.addCase(loadRegions.pending, (state, action) => {
            state.isFetchingRegions = true;
        });

        builder.addCase(loadRegions.fulfilled, (state, action) => {
            state.regions = {};
            action.payload?.forEach((region) => {
                state.regions![region.id] = region;
            });
            state.isFetchingRegions = false;
        });

        builder.addCase(loadRegions.rejected, (state, action) => {
            console.error('Failed to load regions', action.error);
            state.isFetchingRegions = false;
        });

        builder.addCase(switchWorld.pending, (state, action) => {
            state.isSwitchingWorld = true;
        });

        builder.addCase(switchWorld.fulfilled, (state, action) => {
            state.isSwitchingWorld = false;
        });

        builder.addCase(switchWorld.rejected, (state, action) => {
            console.error('Failed to switch world', action.error);
            state.isSwitchingWorld = false;
        });

        builder.addCase(loadInventory.pending, (state, action) => {
            state.isFetchingInventory = true;
        });

        builder.addCase(loadInventory.fulfilled, (state, action) => {
            state.inventory = action.payload;
            state.isFetchingInventory = false;
        });

        builder.addCase(loadInventory.rejected, (state, action) => {
            console.error('Failed to load inventory', action.error);
            state.isFetchingInventory = false;
        });

        builder.addCase(loadSceneSelectorData.fulfilled, (state, action) => {
            state.sceneSelectorData = action.payload;
        });
    },
});

export const {
    toggleInventory,
    setUniverseId,
    toggleShowingSettingsMenu,
    setShowingSettingsMenu,
    setHasWorldIntroPlayed,
    setNewsModalLastVersionSeen,
    setShouldShowGuestSignUpModal,
    setHasSeenShop,
    setIsInventoryOpen,
    setIsSnackbarVisible,
    setArisewareIsSnackbarVisible,
    setIsSupplyRunLeaderboardOpen,
    setIsExitLocationModalOpen,
    openProfile,
    closeProfile,
    updateCurrentlyViewingProfileIfMatches,
    toggleSidebarOpen,
    setIsSidebarOpen,
    toggleShopOpen,
    setIsShopOpen,
    setIsMyPrizesOpen,
    setAuthModalScreen,
    reevaluateIfFeedsAreOpen,
    updateLocation,
    setInventory,
    toggleHasFailed,
    setHasFailed,
    resetAppState,
    selectInventoryItem,
    setIsInventoryUsingItem,
    setIsUnseenItemInInventory,
    queueAchievement,
    removeFirstAchievementFromQueue,
    setShareModalOpen,
    setStartShare,
    setIsPurchaseInProgress,
    setCurrentlyViewingMedia,
    setCurrentlyViewingPrize,
} = app.actions;

export const { actions: appActions, reducer: appReducer } = app;

export default appReducer;
