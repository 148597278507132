import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    ChatEvent,
    ChatMessage,
    ChatParticipantUpdatePayload,
    FeedItem,
    MessageReactionSum,
    PastEvent,
    ReactedBy,
    Reaction,
    ReactionUpdatePayload,
    StoredEvent,
    Update,
} from '@/models/feed';
import * as arise from '@/arise/api';
import { RootState } from '@/state/store';
import { loadUserProfiles } from '@/state/features/profile';
import { pathToURL } from '@/utils/urls';

// import demoFeedItems from '@/components/Feed/demoFeedItems';
// const feedItems = demoFeedItems;

interface FeedState {
    roomId?: string;
    messages: FeedItem[];
    connected: boolean;
    isLoadingPastMessages: boolean;
    loadingPastMessagesError?: string;
    isMessageSubmitting: boolean;
    messageSubmittingError?: string;

    isSubmittingToySuccess: boolean;
    submittingToySuccessError?: string;

    moderationActionOpenOnMessageId: string | null;
    feedUpdateTicker: number;
    availableReactions: { [key: string]: Reaction };

    currentEvent?: ChatEvent;
    storedEvents?: { [key: string]: StoredEvent };
}

const initialState: FeedState = {
    connected: false,
    isLoadingPastMessages: false,
    isMessageSubmitting: false,
    messages: [],
    isSubmittingToySuccess: false,
    moderationActionOpenOnMessageId: null,
    feedUpdateTicker: 0,
    availableReactions: {},
    storedEvents: {},
};

export const initialFeedState = initialState;

export const getPreviousMessages = createAsyncThunk<
    [string, any],
    { id: string; globalChat: boolean; isLatest?: boolean }
>('feed/getPreviousMessages', async ({ id, globalChat, isLatest }) => {
    const messages = await arise.lastChats(id, undefined, undefined, globalChat, isLatest);
    return [id, messages];
});

export const submitMessage = createAsyncThunk<void, [string, string]>(
    'feed/submitMessage',
    async ([locationId, message]) => {
        await arise.sendMessage(locationId, message);
    },
);

export const submitCommand = createAsyncThunk<void, [string, string]>(
    'feed/submitCommand',
    async ([locationId, message]) => {
        await arise.sendCommand(locationId, message);
    },
);

export const toggleMessageReaction = createAsyncThunk<void, [string, string, string]>(
    'feed/toggleMessageReaction',
    async ([messageId, reaction, locationId]) => {
        await arise.toggleMessageReaction(messageId, reaction, locationId);
    },
);

export const loadAvailableReactions = createAsyncThunk<{ [key: string]: Reaction }, undefined>(
    'feed/loadAvailableReactions',
    async () => {
        function convertArrayToObject(arrayOfReactions: Reaction[]) {
            let reactionsAsObject = {};
            arrayOfReactions.forEach((reaction) => {
                reactionsAsObject[reaction.type] = {
                    ...reaction,
                    imageUrl: pathToURL(reaction.imagePath),
                };
            });
            return reactionsAsObject;
        }

        const reactions = await arise.listReactionTypes();
        return convertArrayToObject(reactions);
    },
);

export const getUserDataForChat = createAsyncThunk<void, FeedItem[], { state: RootState }>(
    'feed/getUserDataForChat',
    async (messages, thunkApi) => {
        const userIds = new Set<string>();
        messages.forEach((m) => {
            switch (m.type) {
                case 'chat-message':
                    userIds.add((m.data as ChatMessage).senderId);
                    break;
                case 'feed-update':
                    userIds.add((m.data as Update).subjectId);
                    break;
            }
        });
        thunkApi.dispatch(loadUserProfiles(Array.from(userIds)));
    },
);

export const fetchEventById = createAsyncThunk<PastEvent, string>('feed/fetchEventById', async (eventID) => {
    return await arise.getEventById(eventID);
});

const feed = createSlice({
    name: 'feed',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<FeedItem>) => {
            state.messages.push(action.payload);
        },
        updateItem: (state, action: PayloadAction<FeedItem>) => {
            const index = state.messages.findIndex((m) => m.id === action.payload.id);
            const previousItem = state.messages[index];

            if (index >= 0) {
                // Check if the number of reactions has changed.
                // This is used to trigger the autoscroll, as the height of the message element will change based on whether there are reactions or not.
                if (
                    (previousItem.type === 'chat-message' && action.payload.type === 'chat-message') ||
                    (previousItem.type === 'feed-update' && action.payload.type === 'feed-update')
                ) {
                    const previousReactionCount =
                        previousItem.data.reactions && previousItem.data.reactions.length
                            ? previousItem.data.reactions.length
                            : 0;
                    const newReactionCount =
                        action.payload.data && action.payload.data.reactions ? action.payload.data.reactions.length : 0;

                    if (
                        previousReactionCount !== newReactionCount &&
                        (previousReactionCount > 0 || newReactionCount > 0)
                    ) {
                        state.feedUpdateTicker++;
                    }
                }
                state.messages[index] = action.payload;
            }
        },
        updateMessageReaction: (state, action: PayloadAction<ReactionUpdatePayload>) => {
            const indexOfMessage = state.messages.findIndex((m) => m.id === action.payload.data.id);
            const messageData = state.messages[indexOfMessage]?.data;
            if (!messageData) return;
            if (!messageData.reactions) {
                messageData.reactions = [];
            }
            const messageReactions: MessageReactionSum[] = messageData.reactions;
            const payload = action.payload.data.reaction;

            if (messageReactions) {
                state.feedUpdateTicker++;
                const indexOfReaction = messageReactions.findIndex((r: MessageReactionSum) => r.type === payload.type);
                const reactionToUpdate = messageReactions[indexOfReaction];

                if (payload.action === 'add') {
                    const newUserReactedBy = {
                        userID: action.payload.data.reaction.userID,
                        displayName: action.payload.data.reaction.displayName,
                    };
                    if (reactionToUpdate) {
                        // Reaction type is already on this message
                        // So increase reaction count and add user to list
                        reactionToUpdate.count++;
                        reactionToUpdate.reactedBy.push(newUserReactedBy);
                    } else {
                        // Reaction type is not on this message yet
                        // So add the reaction
                        messageReactions.push({
                            type: payload.type,
                            count: 1,
                            reactedBy: [newUserReactedBy],
                        });
                    }
                } else if (payload.action === 'remove') {
                    if (reactionToUpdate) {
                        // Reaction type is already on this message
                        if (reactionToUpdate.count === 1) {
                            // If only count of 1, remove this reaction completely
                            messageReactions.splice(indexOfReaction, 1);
                        } else {
                            // Otherwise just decrease the count and remove user from list
                            reactionToUpdate.count--;
                            reactionToUpdate.reactedBy = reactionToUpdate.reactedBy.filter(
                                (r: ReactedBy) => r.userID !== payload.userID,
                            );
                        }
                    } else {
                        // Reaction type is not on this message
                        // Should not be possible
                    }
                }
            }
        },

        removeItem: (state, action: PayloadAction<string>) => {
            const index = state.messages.findIndex((m) => m.id === action.payload);
            if (index >= 0) {
                state.messages.splice(index, 1);
            }
        },
        setConnectionStatus: (state, action) => {
            state.connected = action.payload;
        },
        openModerationAction: (state, action) => {
            state.moderationActionOpenOnMessageId = action.payload;
        },
        closeModerationAction: (state) => {
            state.moderationActionOpenOnMessageId = null;
        },
        updateFeedProfile: (state, action) => {
            const profile = action.payload;
            state.messages = state.messages.map((m) => {
                if (m.type === 'chat-message') {
                    const message = m.data as ChatMessage;
                    if (message.senderId === profile.userID) {
                        message.profile = profile;
                    }
                }
                if (m.type === 'feed-update') {
                    const update = m.data as Update;
                    if (update.subjectId === profile.userID) {
                        update.profile = profile;
                    }
                }
                return m;
            });
        },

        addItemsToTop: (state, action: PayloadAction<FeedItem[]>) => {
            state.messages = [...action.payload, ...state.messages];
        },
        setCurrentEvent: (state, action: PayloadAction<ChatEvent>) => {
            state.currentEvent = action.payload;
        },
        setCurrentEventIsActive: (state, action: PayloadAction<boolean>) => {
            state.currentEvent.isActive = action.payload;
        },
        clearCurrentEvent: (state) => {
            state.currentEvent = null;
        },
        updateEventParticipant: (state, action: PayloadAction<ChatParticipantUpdatePayload>) => {
            // Only update if is for current active event
            if (action.payload?.data?.eventID !== state.currentEvent?.eventID) return;

            const participant = { ...action.payload.data.participant, lastUpdated: action.payload.timestamp };
            state.currentEvent.participants = [participant];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPreviousMessages.pending, (state) => {
            state.isLoadingPastMessages = true;
            state.loadingPastMessagesError = undefined;
        });
        builder.addCase(getPreviousMessages.fulfilled, (state, action) => {
            state.isLoadingPastMessages = false;
            state.roomId = action.payload[0];
            state.messages = action.payload[1];
            state.loadingPastMessagesError = undefined;
        });
        builder.addCase(getPreviousMessages.rejected, (state, action) => {
            state.isLoadingPastMessages = false;
            state.loadingPastMessagesError = action.error.message;
        });

        builder.addCase(submitMessage.pending, (state) => {
            state.isMessageSubmitting = true;
            state.messageSubmittingError = undefined;
        });
        builder.addCase(submitMessage.fulfilled, (state) => {
            state.isMessageSubmitting = false;
            state.messageSubmittingError = undefined;
        });
        builder.addCase(submitMessage.rejected, (state, action) => {
            state.isMessageSubmitting = false;
            state.messageSubmittingError = action.error.message;
        });

        builder.addCase(toggleMessageReaction.rejected, (_, action) => {
            console.error(action.error);
        });
        builder.addCase(loadAvailableReactions.fulfilled, (state, action) => {
            state.availableReactions = action.payload;
        });

        builder.addCase(fetchEventById.pending, (state, action) => {
            const id = action.meta.arg;
            state.storedEvents[id] = {
                lastFetched: Date.now(),
                data: null,
            };
        });
        builder.addCase(fetchEventById.fulfilled, (state, action) => {
            // If data is for current event, update the participants to update event status UI
            if (state.currentEvent && state.currentEvent?.eventID === action.payload.eventData.eventID) {
                const event: ChatEvent = {
                    ...state.currentEvent,
                    participants: action.payload.eventData.participants,
                };
                state.currentEvent = event;
            }
            const id = action.meta.arg;
            state.storedEvents[id] = {
                lastFetched: state.storedEvents?.[id]?.lastFetched ?? Date.now(),
                data: action.payload,
            };
        });
    },
});

export const {
    addItem,
    updateItem,
    updateMessageReaction,
    removeItem,
    openModerationAction,
    closeModerationAction,
    updateFeedProfile,
    addItemsToTop,
    setCurrentEvent,
    clearCurrentEvent,
    updateEventParticipant,
    setCurrentEventIsActive,
} = feed.actions;
export const { reducer: feedReducer } = feed;
