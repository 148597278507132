export const homePath = process.env.PUBLIC_HOME_PATH ?? '/selector';

export const isHomePath = (): boolean => {
    const url = new URL(window.location.href);
    return url?.pathname === homePath;
};

export const exitModalText = process.env.PUBLIC_INSTANCE === 'titan' ? 'Back to Camp' : 'Back to World';

export const getInsidersLoginUrl = () => {
    const returnUrl = window.location.origin + '/auth/insiders';
    const base64Url = btoa(returnUrl);
    return `${process.env.PUBLIC_INSIDERS_LOGIN_URL}?return_to=${base64Url}`;
};

export const getInsidersRegisterUrl = () => {
    const returnUrl = window.location.origin + '/auth/insiders';
    const base64Url = btoa(returnUrl);
    return `${process.env.PUBLIC_INSIDERS_REGISTRATION_URL}?return_to=${base64Url}`;
};

export const SUPPORT_EMAIL = 'support@thosebeyond.io';

export const isInsidersInstance = process.env.PUBLIC_INSTANCE === 'titan' || process.env.PUBLIC_INSTANCE === 'hyperion';
export const isThoseBeyondInstance = !isInsidersInstance;
